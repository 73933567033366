import React, { useEffect, useState } from 'react';
import { /*Button,*/ Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import { UPDATE_WITHDRAWAL_STATUS } from 'shared-components/queries/mutations';
import { PAYOUTS_QUERY } from 'shared-components/queries/graphql';
import { useSWCallbackWithdrawalEvent } from 'shared-components/providers/SWEventProvider';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';

import style from './style.module.scss';

const UnhandledWithdrawals = () => {
  const { name } = useMerchantInfo();
  const date = new Date();
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const [lbData, setLbData] = useState<any>([]);

  const payoutsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'payouts',
    data: {
      query: PAYOUTS_QUERY
    },
    auto: true,
    onResponse: (data: any) => {
      const payoutsData = data?.edges
        ?.map((value: any) => {
          const values = value.node;
          values.customer.kyc = JSON.parse(values.customer.kyc);
          return values;
        })
        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      console.log(payoutsData);
      setLbData(payoutsData);
    }
  });

  const _updateWithdrawalStatusQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'updateWithdrawalStatus',
    data: {
      query: UPDATE_WITHDRAWAL_STATUS
    },
    auto: false,
    onResponse: (_data: any) => {
      //payoutsQuery.execute();
    }
  });

  useEffect(() => {
    payoutsQuery.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useSWCallbackWithdrawalEvent((_data: any) => {
    payoutsQuery.execute();
  });

  return (
    <>
      <Card className={style.card}>
        <Card.Title>
          <div style={{ display: 'flex' }}>Withdrawals</div>
        </Card.Title>
        <Card.Text>
          <DataTable
            columns={[
              {
                name: 'Created at',
                selector: (row: any) => `${row?.['createdAt'].replace('T', ' ')}`
              },
              {
                name: 'Payout reference',
                selector: (row: any) => row?.['paymentReference']
              },
              {
                name: 'PID',
                selector: (row: any) => row?.['customer']?.['personId']
              },
              {
                name: 'First name',
                selector: (row: any) => row?.['customer']?.['kyc']?.['person']?.['forename']
              },
              {
                name: 'Last name',
                selector: (row: any) => row?.['customer']?.['kyc']?.['person']?.['surname']
              },
              {
                name: 'Customer IBAN/BBAN',
                selector: (row: any) =>
                  row?.['clientIban'] !== undefined && row?.['clientIban'].length > 0
                    ? row?.['clientIban']
                    : row?.['clientBban']
              },
              {
                name: 'Amount',
                selector: (row: any) => `${row?.['amount'].toFixed(2)} ${row?.['currency'].toUpperCase()}`
              },
              {
                name: 'Status',
                selector: (row: any) => row?.['status']
              }
            ]}
            data={lbData}
            pagination
          />
        </Card.Text>
      </Card>
    </>
  );
};

export default UnhandledWithdrawals;

/*

                    <Button
                      onClick={() => {
                        const test1 = parseIban('SE8030000000041351300039');
                        const test2 = parseIban('SE5860000000000517182351');
                        const test3 = parseIban('SE5580000832790147258925');
                        console.log(test1);
                        console.log(test2);
                        console.log(test3);
                      }}
                    >
                      TEST
                    </Button>
*/
