import Sidebar from 'react-bootstrap-sidebar-menu';
import React, { useCallback, useMemo } from 'react';

import {
  AdminPrefix,
  AdminUrl,
  MerchantPrefix,
  environment,
  merchantportalDocHost
} from 'shared-components/configuration';
import { ApiKeys, Clients, Dashboard, Invoices, Profile } from 'shared-components/assets/icons';
import RoundedText from '../../RoundedText';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import { useGraphQL } from 'shared-components/providers/GraphQLProvider';
import MerchantSwitcher from 'components/MerchantSwitcher';

import { NavLinkAdmin, NavlinkVisibleEnum } from './NavLinkAdmin';

interface NavMenuProps {
  onClickItem?: () => void;
}

const NavMenu = (props: NavMenuProps) => {
  const { disconnect } = useGraphQL();
  const info = useMerchantInfo();

  const prefix = useMemo(() => {
    return environment.startsWith('admin') ? AdminPrefix : MerchantPrefix;
  }, []);

  const handleLogout = useCallback(() => {
    disconnect();
    window.location.href = '/';
  }, [disconnect]);

  const onClick = () => {
    props.onClickItem?.call(this);
  };

  return (
    <>
      <Sidebar.Nav>
        <Sidebar.Text>
          <RoundedText>
            <p className="h6 m-0">{environment.startsWith('admin') ? 'Merchant Admin Portal' : info.iban}</p>
          </RoundedText>
        </Sidebar.Text>
        <MerchantSwitcher />
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.DASHBOARD}`}
          icon={Dashboard}
          title="Dashboard"
          onClick={onClick}
        />
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.ADMIN}
          url={`${prefix}/${AdminUrl.COMPLIANCE}`}
          icon={Dashboard}
          title="Compliance"
          onClick={onClick}
        />
        {/*
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.CLIENT_PAYOUT}`}
          icon={Payout}
          title="Pay-out"
          onClick={onClick}
        />*/}

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.ADMIN}
          url={`${prefix}/${AdminUrl.MERCHANTS}`}
          icon={Clients}
          title="Merchants"
          onClick={onClick}
        />
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.ADMIN}
          url={`${prefix}/${AdminUrl.SUB_MERCHANTS}`}
          icon={Clients}
          title="Sub merchants"
          onClick={onClick}
        />

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.CUSTOMERS}`}
          icon={Clients}
          title="Customers"
          onClick={onClick}
        />
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.INVOICES}`}
          icon={Invoices}
          title="Invoices"
          onClick={onClick}
        />
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.WITHDRAWALS}`}
          icon={Clients}
          title="Withdrawals"
          onClick={onClick}
        />
        {/*
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.BATCHES}`}
          icon={Clients}
          title="Batches"
          onClick={onClick}
        />*/}

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.BOTH}
          url={`${prefix}/${AdminUrl.RFQ_LIST}`}
          icon={Clients}
          title="Payment Initiations"
          onClick={onClick}
        />

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.MERCHANT}
          url={`${prefix}/${AdminUrl.PROFILE}`}
          icon={Profile}
          title="Profile"
          onClick={onClick}
        />
        {/*
        <NavLinkAdmin
          visible={NavlinkVisibleEnum.MERCHANT}
          url={`${prefix}/${AdminUrl.DEPOSIT}`}
          icon={Clients}
          title="Deposit"
          onClick={onClick}
        />*/}

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.MERCHANT}
          url={`${prefix}/${AdminUrl.API_KEYS}`}
          icon={ApiKeys}
          title="API Keys"
          onClick={onClick}
        />

        <NavLinkAdmin
          visible={NavlinkVisibleEnum.MERCHANT}
          url={`${merchantportalDocHost}`}
          icon={ApiKeys}
          title="Documentation"
          onClick={onClick}
          newTab={true}
        />

        <NavLinkAdmin visible={NavlinkVisibleEnum.BOTH} icon={ApiKeys} title="Logout" onClick={handleLogout} />
      </Sidebar.Nav>
    </>
  );
};

export default NavMenu;
