// src/serviceWorker.ts

import {
  autheidWsHost,
  graphqlWsHost,
  debug,
  autheidReconnect,
  graphqlReconnect,
  autheidAutoConnect,
  graphqlAutoConnect,
  autheidDisconnectOnClaim,
  graphqlDisconnectOnClaim,
  useAutheid,
  useGraphql,
  githash
} from './configuration';

export class TimeoutError extends Error {}

/**
 * Run promise but reject after some timeout.
 *
 * @template T
 * @param {number} ms Milliseconds until timing out
 * @param {Promise<T>} promise Promise to run until timeout (note that it will keep running after timeout)
 * @returns {Promise<T, Error>}
 */
export function timeout(ms: any, promise: any) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new TimeoutError());
    }, ms);

    promise.then(
      (result: any) => {
        clearTimeout(timer);
        resolve(result);
      },
      (error: any) => {
        clearTimeout(timer);
        reject(error);
      }
    );
  });
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
type ServiceWorkerRegisterProps = {
  url?: string;
  scope: string;
  onReady?: () => void;
};

export const serviceworkerRegister: any = async ({ scope }: ServiceWorkerRegisterProps) => {
  const hostname = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  const swUrl = new URL(`${hostname}/sw.js`);
  swUrl.searchParams.set('debug', debug);
  swUrl.searchParams.set('rev', githash);
  if (useAutheid) {
    swUrl.searchParams.set('autheidHost', autheidWsHost);
    swUrl.searchParams.set('autheidReconnect', autheidReconnect);
    swUrl.searchParams.set('autheidAutoConnect', autheidAutoConnect);
    swUrl.searchParams.set('autheidDisconnectOnClaim', autheidDisconnectOnClaim);
  }
  if (useGraphql) {
    swUrl.searchParams.set('graphqlHost', graphqlWsHost);
    swUrl.searchParams.set('graphqlReconnect', graphqlReconnect);
    swUrl.searchParams.set('graphqlAutoConnect', graphqlAutoConnect);
    swUrl.searchParams.set('graphqlDisconnectOnClaim', graphqlDisconnectOnClaim);
  }
  const url = swUrl.toString();
  if (!('serviceWorker' in navigator)) throw new Error('serviceWorker not supported');

  console.info('Registering worker');
  try {
    const registration = await navigator.serviceWorker.register(url, {
      scope: scope,
      updateViaCache: 'all',
      type: 'classic'
    });
    console.log(registration);
    return registration;
  } catch (error) {
    // registration failed :(
    console.log('ServiceWorker registration failed:', error);
  }
  /*
  const registeredWorker = registration.active || registration.waiting || registration.installing;
  console.info('Registered worker:', registeredWorker);
  if (registeredWorker?.scriptURL != url) {
    console.log('[ServiceWorker] Old URL:', registeredWorker?.scriptURL || 'none', 'updating to:', url);
    await registration.update();
    console.info('Updated worker');
  }

  console.info('Waiting for ready worker');
  const serviceReg = await navigator.serviceWorker.ready;
  console.info('Ready registration:', serviceReg);
  //navigator.serviceWorker.controller?.postMessage({ service: 'claim' });
  /*
  if (!navigator.serviceWorker.controller) {
    //App.sendMessage({ service: 'claim' });
    console.info('Worker isn’t controlling, re-register');
    try {
      /*
      const reg = await navigator.serviceWorker.getRegistrations();
      console.info('Unregistering worker');

      for (const registration of reg) {
        //await registration.unregister();
      }
        
      console.info('Successfully unregistered, trying registration again');
      await delay(1000);
//      location.reload();
      //return await serviceworkerRegister({ url, scope });
    } catch (error) {
      console.error(`ServiceWorker failed to re-register after hard-refresh, reloading the page!`, error);
      return location.reload();
    }
  }

  let serviceWorker: any = serviceReg?.active || serviceReg?.waiting || serviceReg?.installing;
  if (!serviceWorker) {
    console.info('No worker on registration, getting registration again');
    const serviceReg2 = await navigator.serviceWorker.getRegistration(scope);
    //    console.log('TEST???');
    serviceWorker = serviceReg2?.active || serviceReg2?.waiting || serviceReg2?.installing;
  }

  if (!serviceWorker) {
    console.info('No worker on registration, waiting 50ms');
    await delay(1000);
    //    await timeout(100, () => {
    //      console.log('test');
    //    }); // adjustable or skippable, have a play around
  }

  serviceWorker = serviceReg.active || serviceReg.waiting || serviceReg.installing;
  if (!serviceWorker) return;
  //if (!serviceWorker) throw new Error('after waiting on .ready, still no worker');

  if (serviceWorker?.state == 'redundant') {
    console.info('Worker is redundant, trying again');
    return await serviceworkerRegister({ url, scope });
  }
  const tryOnce = true;
  if (serviceWorker?.state != 'activated') {
    console.info('Worker IS controlling, but not active yet, waiting on event. state=', serviceWorker?.state);
    try {
      // timeout is adjustable, but you do want one in case the statechange
      // doesn't fire / with the wrong state because it gets queued,
      // see ServiceWorker.onstatechange MDN docs.
      await timeout(
        4000,
        new Promise<void>((resolve) => {
          serviceWorker?.addEventListener('statechange', async (e: any) => {
            console.log(navigator.serviceWorker.controller);
            if (e.target.state == 'activated' && navigator.serviceWorker.controller !== null) {
              console.log('TEST');
              resolve();
            } else {
              console.log('RELOAD');
              //return serviceworkerRegister({ url, scope });
              location.reload();
            }
          });
        })
      );
    } catch (error) {
      if (error instanceof TimeoutError) {
        if (serviceWorker?.state != 'activated') {
          if (tryOnce) {
            console.info('Worker is still not active. state=', serviceWorker?.state);
            throw new Error('failed to activate service worker');
          } else {
            console.info('Worker is still not active, retrying once');
            //location.reload();
            return serviceworkerRegister({ url, scope });
          }
        }
      } else {
        // should be unreachable
        throw error;
      }
    }
  }

  console.info('Worker is controlling and active, we’re good folks!');
  navigator.serviceWorker.startMessages();
  App.sendMessage({ service: 'skipwaiting' });
  App.sendMessage({ service: 'claim' });
  return serviceWorker;
  */
};

export async function register(
  scope = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('beforeunload', async () => {
      await unregister();
    });
    window.addEventListener('load', async () => {
      navigator.serviceWorker.addEventListener('updatefound', () => {
        console.log('Service Worker update detected!');
      });
      navigator.serviceWorker.addEventListener('controllerchange', function () {
        App.sendMessage({ service: 'status' });
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        App.logData(event);
      });
      serviceworkerRegister({
        scope: scope
      });
    });
  }
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
      console.log('Service worker unregistered');
    }

    if ('caches' in window) {
      const keyList = await caches.keys();
      await Promise.all(keyList.map((key) => caches.delete(key)));
    }

    if ('serviceWorker' in navigator) {
      const registrationsAgain = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrationsAgain) {
        await registration.unregister();
        console.log('Service worker unregistered');
        //        setTimeout(() => {
        //         console.log('Trying redirect');
        //         window.location.replace(window.location.href);
        //       }, 3000);
      }
    }
  }
}
