import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';

import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
//import MtbBalance from 'components/MtbBalance';
//import MtbTransactions from '../MtbTransactions';

import { DailyStats /*MonthlyStats*/ } from './stats';

const DashboardAdmin = () => {
  const _query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'statementProxy',
    data: {
      query: `mutation statementProxy($input: JSONString!) {
            statementProxy(input: $input) {
                result
            }
      }`,
      variables: {
        input: JSON.stringify({
          statement: {
            accountId: 'ID-1004077',
            detailed: true,
            daysBehind: -2,
            daysAhead: 0
          }
        })
      }
    },
    auto: false,
    onResponse: (data: any) => {
      const result = data.result;
      const jsonResult = JSON.parse(result);
      console.log(jsonResult);
      if (jsonResult.statement !== undefined) {
        const binaryData = atob(jsonResult.statement);

        // Convert binary data to a Blob
        const blob = new Blob([new Uint8Array([...binaryData].map((char) => char.codePointAt(0) as number))], {
          type: 'application/pdf'
        });

        // Create a URL for the Blob and trigger download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.pdf';
        link.click();

        // Revoke the Blob URL after the download
        URL.revokeObjectURL(url);
      }
    }
  });

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {/*
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    statement: {
                      accountId: 'ID-1004077',
                      detailed: true,
                      daysBehind: -30,
                      daysAhead: 0,
                      currency: 'sek'
                    }
                  })
                }
              })
            }
          >
            Get Stats STAGING ID
          </Button>
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    statement: {
                      accountId: 'ID-72616',
                      detailed: true,
                      daysBehind: -15,
                      daysAhead: 0,
                      currency: 'sek',
                      fileType: 'csv'
                    }
                  })
                }
              })
            }
          >
            Get Stats PROD ID
          </Button>
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    accountDetails: 'ID-1004077'
                  })
                }
              })
            }
          >
            Get details STAGING ID
          </Button>
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    accountDetails: 'ID-72616'
                  })
                }
              })
            }
          >
            Get details PROD ID
          </Button>
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    txHistory: {
                      accountId: 'ID-1004077',
                      daysBehind: -30,
                      pageSize: 10,
                      pageNr: 1
                    }
                  })
                }
              })
            }
          >
            Get tx history STAGING ID
          </Button>
          <Button
            onClick={() =>
              query.execute({
                variables: {
                  input: JSON.stringify({
                    txHistory: {
                      accountId: 'ID-72616',
                      daysBehind: -1,
                      pageSize: 10,
                      pageNr: 1,
                      currency: 'eur'
                    }
                  })
                }
              })
            }
          >
            Get tx history PROD ID
          </Button>
          */}
          <DailyStats />
          {/*
          <MtbTransactions />
          <MtbBalance />
          <MonthlyStats />
          */}
        </Col>
        <Col>
          <Container fluid>
            {/* <PendingTransactions /> */}
            {/* <Stats /> */}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardAdmin;
