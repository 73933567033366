import { useCallback, useEffect, useState } from 'react';

import { Method, Service } from 'shared-components/configuration';

import useSWQuery from './useSWQuery';

export const useOrchStats = () => {
  const [stats, setStats] = useState<any>([]);
  const [merchant, setMerchant] = useState<string>('');
  const [daysBehind, setDaysBehind] = useState<number>(1);
  const statsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'piVolumes',
    data: {
      query: `mutation piVolumes($input: JSONString!) {
            piVolumes: orchProxy(input: $input) {
                result
            }
      }`,
      variables: {}
    },
    auto: false,
    onResponse: (data: any) => {
      const result = data.result;
      const jsonResult = JSON.parse(result);
      setStats(jsonResult.getPIVolumes);
    }
  });

  const refreshStats = useCallback(() => {
    statsQuery.execute({
      variables: {
        input: JSON.stringify({
          getPIVolumes: {
            merchantId: merchant,
            currency: 'sek',
            cutoff: Math.floor(Date.now() / 1000) - 86_400 * daysBehind
          }
        })
      }
    });
  }, [daysBehind, merchant, statsQuery]);

  useEffect(() => {
    if (merchant.length > 0 && daysBehind > 0) {
      refreshStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, daysBehind]);
  return { stats, refreshStats, merchant, setMerchant, setDaysBehind };
};
